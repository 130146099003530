<template>
	<div
		class=""
		style="margin-top: 24px !important;"
	>
		<div class="bg-heart text-right pa-20 radius-12 flex-row justify-center">
			<div class="pr-20" style="flex: 1 1 0;">
				<div class="size-px-13 color-333">보유 중인 불렛</div>
				<div class="mt-10 color-red-dc size-px-20 font-weight-bold line-height-1">{{ total_point | makeComma }}불렛</div>
			</div>
			<div class="pl-20 flex-column justify-center gap-5"
				style="border-left:1px solid rgba(220, 80, 92, 0.1); align-items: stretch; flex: 1 1 0;">
				<div class="justify-space-between items-center">
					<div class="size-px-13">{{ $language.point.title_my_point_available }} <!-- 사용가능한 불렛--></div>
					<div class="color-red-dc size-px-14 font-weight-bold ">{{ item.holding_point | makeComma }}불렛 <!--불렛--></div>
				</div>
				<div class="justify-space-between items-center">
					<div class="size-px-13">{{ $language.point.title_my_point_support }} <!-- 후원받은 불렛--></div>
					<div class="color-red-dc size-px-14 font-weight-bold">{{ item.holding_spnsh_point | makeComma }}불렛 <!--불렛--></div>
				</div>
			</div>
		</div>

		<div
			class="mt-20 pb-20 justify-space-between"
		>
			<div>
				<button
					class="flex-1 btn-inline mr-10 radius-20 font-weight-500 pa-13-30"
					:class="is_charge ? 'btn_fill_blue' : 'btn_fill_lightgray' "

					@click="to('PointList')"
				>충전</button>

				<button
					class="flex-1 btn-inline radius-20 font-weight-500 pa-13-30"
					:class="is_list ? 'btn_fill_blue' : 'btn_fill_lightgray' "

					@click="to('PointHistory')"
				>내역</button>
			</div>

			<button
				class="btn-inline radius-20 btn-primary-outline pa-13-30"
				@click="toHistory"
			>결제 내역</button>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'PaymentInfo'
		, props: ['user']
		, data: function(){
			return {
				item: {}
			}
		}
		, computed: {
			is_charge: function(){
				let t = false
				if(this.$route.name == 'PointList'){
					t = true
				}

				return t
			}
			, is_list: function(){
				let t = false
				if(this.$route.name == 'PointHistory'){
					t = true
				}

				return t
			}
		}
		, methods: {
			to: function(name){
				this.$bus.$emit('to', { name: name})
			}

			, getData: async function(){
				try{
					this.$bus.$emit('on', true)
					const result = await this.$Request({
						method: 'post'
						, url: this.$api_url.api_path.get_user_point
						, data: {
							member_number: this.user.member_number
						}
						, type: true
					})
					if(result.success){
						this.item = result.data
						this.$emit('onLoad', this.item)
					}else{
						throw result.message
					}
				}catch (e) {
					console.log(e)
					this.$bus.$emit('notify', { type: 'error', message: e})
				}finally {
					this.$bus.$emit('on', false)
				}
			}
			, toHistory: function(){
				this.$bus.$emit('to', { name: 'PaymentHistory'})
			}
		}
		, created() {
			this.getData()
		}
	}
</script>

<style>
</style>