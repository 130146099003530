<template>
	<div
		class="mt-30 text-left flex-row"
	>
		<MypageSide
			:program="program"
			:user="user"
		></MypageSide>

		<div
			class="flex-1 bg-white pa-24 radius-20"
		>
			<div>
				<div class="under-line-bbb pb-16 flex-row">
					<h3 class="flex-1 size-px-20">{{ program.title }}</h3>
				</div>
				<PaymentInfo
					v-if="is_payment_info"
					:user="user"
					:item="item"
					class="mt-50 "
				>
				</PaymentInfo>

				<div
					class="full-height"
				>
					<ul
						v-if="items.length > 0"

						class="grid-list gap-20"
					>
						<li
							v-for="(point, p_index) in items"
							:key="'point_' + p_index"
							class="box width-100 pa-20 radius-12 justify-space-between items-center bg-heart-only cursor-pointer"
							style="border: 1px solid #e9e9e9;"
							@click="setPoint(point)"
						>
							<div class="flex-1 ml-40 ">
								<div class="size-px-16 font-weight-bold color-red-dc">{{ point.charg_point | makeComma }}불렛</div>
								<div class="size-px-14 font-weight-500 pl-20 bg-won">{{ point.charg_payment_amount | makeComma}}원</div>
							</div>
							<div>
								<button
									class="btn btn-primary-outline radius-20 line-height-1 size-px-12 font-weight-500"
									style="padding: 9px 14px;"
								>구매하기</button>
							</div>
						</li>
					</ul>
					<div
						v-else
						class="ptb-50 size-px-14 text-center"
					>구매가능한 포인트 정보가 없습니다.</div>
				</div>

				<PopupLayer
					v-if="is_on_payment"
					@click="onPin"
					@cancel="offPayment"
				>
					<template
						v-slot:body
					>
						<div
							class="bg-white width-400 ma-auto radius-20 pa-40-30 position-relative"
							style="box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);"
						>
							<div
								class="bg-logo full-height flex-column justify-center items-center pb-20 under-line mb-20"
							>
								<h4>불렛 충전</h4>
								<div class="popup-close">
									<img class="cursor-pointer"
										@click="offPayment"
										:src="require('@/assets/image/web/notify_cartel/ico_close_bk.svg')"
									/>
								</div>
							</div>
							<div class="bg-heart flex-row justify-center gap-10 w-100 pa-20 radius-12"
								style="background-image: none;"
							>
								<img :src="require('@/assets/image/icon_heart.svg')" style="width: 30px" />
								<span class="size-px-18 color-red-dc font-weight-700">
									{{ item_payment.charg_point | makeComma }}불렛
								</span>
							</div>

							<div class="flex-row justify-space-between align-center size-px-14 mt-20 under-line pb-20">
								<div class="font-weight-400 color-333">총 결제 금액</div>
								<div class="font-weight-bold color-red-dc size-px-16">{{ item_payment.charg_payment_amount | makeComma}}원</div>
							</div>
							<div class="mtb-30">
							<label
								@click="is_agree = !is_agree"
							>
								<v-icon
									v-if="is_agree"
									class="color-mafia-blue"
								>mdi-checkbox-marked-circle</v-icon>
								<v-icon
									v-else
								>mdi-checkbox-blank-circle-outline</v-icon>
								<span class="size-px-14 ml-5">상기 상품 유의사항에 동의합니다.(필수)</span>
							</label>
						</div>
							<div
								class="mt-30 text-center"
							>
								<button
									@click="onPin"
									:disabled="!is_agree"
									class="btn-primary radius-20 pa-13-30 size-px-14 font-weight-500 line-height-1"
									style="outline: none;"
								>확인</button>
							</div>
						</div>
					</template>
				</PopupLayer>

				<Popup_select
					v-if="is_on_credit"
					@click="setCredit"
					@cancel="is_on_credit = false"
				>
					<template
						v-slot:title
						class="justify-space-between"
					>{{ $language.common.credit_select }}</template>
					<template
						v-slot:list
					>
						<li
							v-for="(credit, index) in items_credit"
							:key="'item_' + index"
							@click="setCredit(credit)"
							class="mb-10"
						>
							{{ credit.payment_mthd_name }}
							({{ credit.payment_mthd_reg_number.slice(-4).replaceAll('X', '') }})
							<label
								v-if="credit.representation_payment_fg == 'Y'"
								class="label label-primary"
							> {{ $language.mypage.credit.credit_setup_name }}</label>
						</li>
						<li class="mt-20">
							<div class="flex-row justify-center">
								<button
									@click="toCredit"
									class="btn-primary pa-13-30 radius-20 flex-row gap-5 items-end"
									style="outline: none;"
								>
								<img :src="require('@/assets/image/web/mypage_cartel/icon_plus.svg')">
								<span class="size-px-14 line-height-1">{{ $language.mypage.credit_regist }}</span>
							</button>
							</div>
						</li>
					</template>
				</Popup_select>

				<PopupLayer
					v-if="is_on_result"
				>
					<template
						v-slot:body
					>
						<div
							class="bg-white width-400 ma-auto radius-20 pa-40-30 position-relative"
							style="box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);"
						>
							<div
								class="bg-logo full-height flex-column justify-center items-center pb-20 under-line mb-20"
							>
								<h4>불렛 충전 완료</h4>
								<div class="popup-close">
									<img class="cursor-pointer"	@click="offResult" :src="require('@/assets/image/web/notify_cartel/ico_close_bk.svg')"/>
								</div>
							</div>
							<div class="mt-30 bg-heart-pink pa-20 width-100 radius-12 text-center color-red-dc size-px-16 font-weight-600 flex-row gap-5 justify-center align-center"><img :src="require('@/assets/image/icon_heart.svg')" style="width: 30px;"/> 
								<span class="line-height-1"> {{ item_payment.charg_point | makeComma }}불렛</span>
							</div>
							<div
								class="mt-20 size-px-14"
							>
								<div class="justify-space-between">
									<span>결제 수단</span>
									<span>{{ item_credit.payment_mthd_number }}</span>
								</div>
								<div class="mt-10 justify-space-between">
									<span>결제 금액</span>
									<span class="color-red-dc font-weight-700 size-px-16">{{ item_payment.charg_payment_amount | makeComma }}원</span>
								</div>
								<div
									class="mt-30 text-center"
								>
									<button
										@click="offResult"
										class="btn-primary pa-13-30 radius-20 size-px-14 font-weight-500 line-height-1"
										style="outline: none;"
									>확인</button>
								</div>
							</div>
						</div>
					</template>
				</PopupLayer>
			</div>
		</div>
	</div>
</template>

<script>

import PaymentInfo from "@/view/Payment/PointInfo";
import Popup_select from "@/view/Layout/PopupSelect";
import MypageSide from "@/view/Mypage/mypage-lnb";
import PopupLayer from "@/view/Layout/PopupLayer";
export default {
	name: 'PaymentList'
	, components: {PopupLayer, MypageSide, Popup_select, PaymentInfo}
	, props: ['user']
	, data: function(){
		return {
			program: {
				name: 'payment list'
				, title: 'MY 불렛'
				, not_footer: true
				, type: 'mypage'
				, from: 'myPage'
				, is_save: true
				, callBack: {
					name: 'to_payment_history'
					, title: '결제내역'
				}
				, bg_contents: 'bg-gray01'

			}
			, item: {
				point: 0
			}
			, items: [
				{ point: 100, amount: 5000}
				, { point: 500, amount: 60000}
				, { point: 1000, amount: 120000}
				, { point: 2000, amount: 240000}
				, { point: 3000, amount: 360000}
				, { point: 4000, amount: 480000}
				, { point: 5000, amount: 600000}
			]
			, item_payment: {}
			, item_credit: {}
			, is_on_payment: false
			, is_on_credit: false
			, items_credit: []
			, item_search: {
				page: 1
				, list_cnt: 10
			}
			, item_point: {

			}
			, item_result: {

			}
			, is_on_result: false
			, is_payment_info: true
			, is_agree: false
		}
	}
	, computed: {

	}
	, methods: {

		getData: async function(){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.get_user_point_list
					, data: {
						member_number: this.user.member_number
						, mobile_opsys_code: this.$common.getOsCode()
						, payment_type_code: this.$common.getPaymentCode()
					}
					, type: true
				})

				if(result.success){
					this.item_point = result.data
					this.items = result.data.point_charg_info_list
				}else{
					throw result.message
				}
			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, postPayment: async function(pinnumber){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.post_user_point
					, data: {
						member_number: this.user.member_number
						, point_number: this.item_point.point_number
						, point_charg_number: this.item_payment.point_charg_number
						, pinnumber: pinnumber
						, payment_mthd_number: this.item_credit.payment_mthd_number
						, charg_point: Number(this.item_payment.charg_point)
						, charg_payment_amount: Number(this.item_payment.charg_payment_amount)
					}
					, type: true
				})

				if(result.success){
					this.item_result = result.data
					this.is_on_result = true
					this.is_payment_info = false
				}else{
					throw result.message
				}
			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, onPayment: function(item){
			this.is_on_payment = true
			this.item_payment = item
		}
		, offPayment: function(){
			this.is_on_payment = false
			this.item_payment = {}
		}
		, onPin: function(){
			this.$bus.$off('pinCallback')
			this.$bus.$emit('onPin', 'check')
			this.$bus.$on('pinCallback', (type, pin) => {
				this.postPayment(pin)
			})
		}
		, onCredit: function(){
			this.is_on_credit = true
		}
		, setCredit: function(credit){
			this.item_credit = credit
			this.is_on_credit = false
			this.is_on_payment = true
		}
		, getCreditList: async function(){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.get_credit_list
					, data: {
						member_number: this.user.member_number
						, page_number: this.item_search.page
						, pagerecnum: this.item_search.list_cnt
					}
					, type: true
				})
				if(result.success){
					this.items_credit = result.data.payment_mthd_list
				}else{
					throw result.message
				}
			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, toCredit: function(){
			this.$bus.$emit('to', { name: 'mafiaCreditRegist'})
		}
		, setPoint: function(item){
			this.item_payment = item
			this.onCredit()
		}
		, offResult: function(){
			this.is_on_payment = false
			this.is_on_result = false
			this.is_payment_info = true
		}
	}
	, created() {
		this.$emit('onLoad', this.program)
		this.getData()
		this.getCreditList()

		this.$bus.$off('callBack')
		this.$bus.$on('callBack', (call) => {
			if(call.name == 'to_payment_history'){
				this.$emit('to', { name: 'PaymentHistory'})
			}
		})
	}
}
</script>

<style scoped>
.bg-heart-only{
	background-size: 30px;
}

.bg-won{
	background-size: 18px;
	background-position:left bottom;
}
</style>